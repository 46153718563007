import React, { useEffect } from 'react';
import Form from '@/components/pageBuilder/Elements/Form';
import {
  ContentWrapper,
  FormParagraph,
  FormParagraphWrapper,
  Title,
  UnderlinedParagraph,
} from './RegisterForms.styles';
import { useFormHandler } from './useFormHandler';
import { IFormProps } from './RegisterForms.types';
import { Spacer } from '../RegisterAccount.styles';

export const BillingDetails = (props: IFormProps & { setShowTermsAndConditions: (show: boolean) => void }) => {
  const {
    form: billingForm,
    handleInputChange: handleBillingInputChance,
    handleInputBlur: handleBillingInputBlur,
  } = useFormHandler('billing');

  return (
    <>
      <ContentWrapper>
        <Title formTheme={props.formTheme}>Billing Details</Title>
        <Form
          customInputLabelSize="14px"
          formInputs={billingForm}
          onInputChange={handleBillingInputChance}
          onInputBlur={handleBillingInputBlur}
          shouldHaveFullWidthOnMobile={true}
          formValidation={isValid => {
            props.validCallback(isValid, 'billing');
          }}
          formSubmit={form => props.onSubmit && props.onSubmit(form, 'billing')}
          formTheme={props.formTheme}
        />
        <Spacer h={50} w={100} />
      </ContentWrapper>
      <FormParagraphWrapper>
        <FormParagraph formTheme={props.formTheme} size={20}>
          {`By proceeding and clicking on “Create my Account” you are accepting the `}
          <UnderlinedParagraph
            href="https://n.vodafone.ie/content/dam/gowingmyself/pdfs/vbu-pdfs-2023/Vodafone%20Business%20Marketplace%20Terms-%20as%20of%20February%202023.pdf"
            target="_blank"
          >
            Terms and Conditions{' '}
          </UnderlinedParagraph>{' '}
          {`and `}
          <UnderlinedParagraph href="https://n.vodafone.ie/privacy.html" target="_blank">
            Privacy Supplement current
          </UnderlinedParagraph>
        </FormParagraph>
      </FormParagraphWrapper>
    </>
  );
};
