import { useLocation } from '@reach/router';
import axios from 'axios';
import { withPrefix } from 'gatsby';
import path from 'path';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useAllowedUris } from '@/hooks';
import { useModalContext } from '@/hooks/modal/useModal';
import { redirectWithAuthCode } from '@/services/auth.services';
import { eligibility, users } from '@/services/user.services';
import {
  setIsFetching,
  setLoadingUserSession,
  setRedirectPath,
  setRedirectUri,
  setUserSession,
  useAppDispatch,
  useAppSelector,
  UserBlocklistError,
  UserSessionSliceProps,
} from '@/store';
import usingOpcoUrl, { getOpcoUrl } from '@/helpers/prefixHelper';
import { OPCOOptions } from '@/types/Services.types';

const OPCO = process.env.GATSBY_OPCO;

export const SessionContext = createContext({
  searchValue: '',
  setSearchValue: (value: string) => {},
});

export const useSessionContext = () => useContext(SessionContext);

export const UserSessionProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { userSession } = useAppSelector(state => state);
  const { user, isLoading, redirectPath, redirectUri, error, isFetching } = userSession;

  const location = useLocation();
  const { origin, pathname, search } = location;
  const auth_code = new URLSearchParams(search).get('code');

  const allowedUris = useAllowedUris();
  const { toggleModal, setErrorInfo } = useModalContext();

  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (!allowedUris.isSelectAccountPage) {
      if (allowedUris.isProdListPage) {
        dispatch(setRedirectPath(withPrefix(`${path.join('/', allowedUris.isProdListPage.path)}`)));
      } else if (allowedUris.isOfficeAnywherePage) {
        dispatch(setRedirectPath(withPrefix(allowedUris.paths.officeAnywherePage)));
      } else if (allowedUris.isMicrosoft365ForBusinessBundlePage) {
        dispatch(
          setRedirectPath(withPrefix(`${path.join('/', allowedUris.isMicrosoft365ForBusinessBundlePage.path)}`))
        );
      } else {
        dispatch(setRedirectPath(withPrefix(`${path.join('/', pathname, search)}`)));
      }
    }
  }, []);

  useEffect(() => {
    if (redirectPath) {
      dispatch(setRedirectUri(getOpcoUrl(`${path.join('/', redirectPath)}`)));
    }
  }, [pathname, redirectPath]);

  useEffect(() => {
    (async () => {
      if (!isLoading && !user && !error && redirectUri && auth_code) {
        dispatch(setUserSession({ isLoading: true, error: null }));
        dispatch(setIsFetching(false));
      }
      if (isLoading && !user && !error && redirectUri && auth_code && !isFetching) {
        dispatch(setIsFetching(true));
        const res = await redirectWithAuthCode(
          getOpcoUrl(`${origin}${path.join('/', allowedUris.paths.selectAccountPage)}`),
          auth_code
        );
        const stateUUID = new URLSearchParams(search).get('state');
        const sessionState = sessionStorage.getItem('state');
        if (!axios.isAxiosError(res) && (res.status === 201 || res.data === 'Created') && stateUUID === sessionState) {
          const eligibilityRes = await eligibility();

          if (!axios.isAxiosError(eligibilityRes) && eligibilityRes.status === 200) {
            const sessionId = res.headers['x-csrf-token'];
            const userData = {
              ...eligibilityRes.data,
              csrfToken: sessionId,
            };

            dispatch(setUserSession({ isLoading: false, user: userData, error: null }));

            if (eligibilityRes.data.accounts.length === 1 && OPCO !== OPCOOptions.PORTUGAL) {
              dispatch(setLoadingUserSession(true));
              const accountInfo = eligibilityRes?.data.accounts[0];

              if (accountInfo) {
                dispatch(setUserSession({ isLoading: true, error: null }));

                const usersRes = await users({
                  customerAccountId: accountInfo.customerAccountId,
                  customerId: accountInfo.customerId,
                  companyName: accountInfo.companyName,
                  barId: accountInfo.barId,
                  segment: accountInfo.segment,
                });

                if (!axios.isAxiosError(usersRes) && usersRes.status === 200) {
                  dispatch(
                    setUserSession({
                      isLoading: false,
                      user: {
                        ...usersRes.data,
                        ...userData,
                        selectedAccount: accountInfo,
                      },
                      error: null,
                    })
                  );
                  usingOpcoUrl(userSession.redirectPath || withPrefix('/'), true, {
                    state: { user: eligibilityRes.data },
                  });
                } else {
                  dispatch(
                    setUserSession({
                      isLoading: false,
                      ...(axios.isAxiosError(res) && { error: res.toJSON() as UserSessionSliceProps['error'] }),
                    })
                  );
                  setErrorInfo({
                    ...(axios.isAxiosError(res) && {
                      statusType: res.response?.data?.error,
                      statusCode: res.response?.data?.code || res.response?.status,
                    }),
                    requestUrl: 'users',
                  });
                  toggleModal(true, 'Error');
                }
              }
            }
            // NOTE: Correct response
            // navigate(withPrefix('marketplace/multiple-billing-account'), { state: { user: eligibilityRes.data } });
          } else if (
            axios.isAxiosError(eligibilityRes) &&
            (eligibilityRes.response?.status === 403 || eligibilityRes.response?.status === 404)
          ) {
            dispatch(
              setUserSession({
                isLoading: false,
                error: eligibilityRes.response?.data as UserBlocklistError,
              })
            );
          } else {
            dispatch(
              setUserSession({
                isLoading: false,
                ...(axios.isAxiosError(eligibilityRes) && { error: eligibilityRes.response?.data }),
              })
            );
            setErrorInfo({
              ...(axios.isAxiosError(eligibilityRes) && {
                statusType: eligibilityRes.response?.data?.error,
                statusCode: eligibilityRes.response?.data?.code || eligibilityRes.response?.status,
              }),
              requestUrl: 'eligibility',
            });
            toggleModal(true, 'Error');
          }
        } else {
          dispatch(setUserSession({ isLoading: false, ...(axios.isAxiosError(res) && { error: res.response?.data }) }));
          setErrorInfo({
            ...(axios.isAxiosError(res) && {
              statusType: res.response?.data?.error,
              statusCode: res.response?.status,
            }),
            requestUrl: 'login',
          });
          toggleModal(true, 'Error');
        }
      }
    })();
  }, [user, isLoading, redirectUri, auth_code, error]);

  useEffect(() => {
    if (error && user?.organizationId) {
      isEnvDevelopment() && console.log('error: ', error);
    }
  }, [error]);

  return (
    <SessionContext.Provider
      value={{
        searchValue,
        setSearchValue,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
