import { Box, Flex, Stack, VStack } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { navigate, withPrefix } from 'gatsby';
import { rem } from 'polished';

import Container from '@/components/Global/Container';
import { useModalContext } from '@/hooks/modal/useModal';

import { OptionDestinationProps } from '../SWConfigureBundleOptions/SWConfigureBundleOptions.types';
import { CancelButton, titleBusinessNeedsStyles } from './SWConfigureBusinessNeeds.styles';
import { SWConfigureBusinessNeedsProps } from './SWConfigureBusinessNeeds.types';
import BundleNeedsOption from './BundleNeedsOption';
import usingOpcoUrl from '@/helpers/prefixHelper';
import handleTranslation from '@/helpers/handleTranslation';
import { Button } from '@legacy-vfuk/core-button';
const SWConfigureBusinessNeeds = ({
  id,
  contentful_id,
  sectionTitle,
  leftButtonDestinationType,
  leftButtonDestinationLink,
  options,
}: SWConfigureBusinessNeedsProps) => {
  const sectionId = `${id}-${contentful_id}`;
  const { toggleModal } = useModalContext();
  const back = handleTranslation('Back', 'Voltar');
  const next = handleTranslation('Next', 'Seguinte');

  const handleButtonAction = (
    buttonDestinationType: OptionDestinationProps['destinationType'],
    configurationButtonDestinationLink: OptionDestinationProps['destinationLink'],
    buttonDestinationLink: OptionDestinationProps['destinationLink']
  ) => {
    if (buttonDestinationType === 'internal' && configurationButtonDestinationLink) {
      usingOpcoUrl(withPrefix(`${configurationButtonDestinationLink}`), true);
    } else if (buttonDestinationType === 'external' && configurationButtonDestinationLink) {
      usingOpcoUrl(`${configurationButtonDestinationLink}`, true);
    } else if (buttonDestinationType === 'internal' && buttonDestinationLink) {
      usingOpcoUrl(withPrefix(`${buttonDestinationLink}`), true);
    } else if (buttonDestinationType === 'external' && buttonDestinationLink) {
      usingOpcoUrl(`${buttonDestinationLink}`, true);
    } else {
      toggleModal(true, 'MsDomain');
    }
  };

  return (
    <Box
      as="section"
      id={sectionId}
      backgroundColor="white"
      py={{ base: rem('17px'), md: rem('30px'), lg: rem('30px') }}
      h="full"
      style={{ minHeight: '100vh' }}
    >
      <Container pb={{ base: rem('82px'), md: 0 }}>
        <Flex direction={{ base: 'column', md: 'row' }} textAlign="center">
          <CancelButton>
            <Button
              icon={{
                name: 'chevron-left',
                justify: 'left',
              }}
              text={back}
              {...(!leftButtonDestinationLink && {
                state: 'disabled',
              })}
              href={usingOpcoUrl(
                leftButtonDestinationType === 'internal' && leftButtonDestinationLink
                  ? withPrefix(leftButtonDestinationLink)
                  : leftButtonDestinationLink
              )}
              appearance="alt1"
            />
          </CancelButton>

          <Flex direction="column" textAlign="center" {...titleBusinessNeedsStyles}>
            {documentToReactComponents(JSON.parse(sectionTitle.raw))}
          </Flex>

          <CancelButton visibility="hidden" display={{ base: 'none', md: 'flex' }}>
            <Button
              icon={{
                name: 'chevron-right',
                justify: 'right',
              }}
              text={next}
              appearance="alt1"
            />
          </CancelButton>
        </Flex>

        <Flex align="center" direction="column" mt={{ base: rem('90px'), md: rem('77px'), lg: rem('98px') }}>
          <VStack maxW={rem('780px')}>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 4, md: 5 }}>
              {options.map(option => (
                <BundleNeedsOption
                  key={`${option.id}${option.contentful_id}`}
                  option={option}
                  handleButtonAction={handleButtonAction}
                />
              ))}
            </Stack>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default SWConfigureBusinessNeeds;
