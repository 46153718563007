import { IFormInput } from '@/components/pageBuilder/Elements/Form/Form.types';

export const addressFormConfig: IFormInput[] = [
  {
    inputType: 'inputField',
    name: 'address1',
    label: 'Address 1',
    fullWidth: true,
    requiredField: true,
    validationType: 'length',
    customErrorMessage: 'Please enter the address',
    placeholder: 'Enter your address',
    hidden: true,
    lengthValidationSize: 1,
  },
  {
    inputType: 'inputField',
    name: 'address2',
    label: 'Address 2',
    fullWidth: true,
    placeholder: 'Enter your address',
    hidden: true,
  },
  {
    inputType: 'inputField',
    name: 'postcode',
    label: 'Eircode',
    fullWidth: true,
    requiredField: true,
    hidden: true,
    lengthValidationSize: 2,
    validationType: 'postCode',
    dataType: 'alphanum',
    placeholder: 'Enter your Eircode',
    customErrorMessage: 'Please enter a valid postal code (Eircode).',
  },
  {
    inputType: 'inputField',
    name: 'city',
    label: 'City',
    fullWidth: false,
    placeholder: 'Enter your city',
    validationType: 'length',
    lengthValidationSize: 1,
    customErrorMessage: 'Please enter the city',
    customWidth: '33%',
    requiredField: true,
    hidden: true,
  },
  {
    inputType: 'select',
    name: 'stateOrProvince',
    label: 'County',
    fullWidth: false,
    requiredField: true,
    hidden: true,
    placeholder: '',
    customWidth: '33%',
    options: [{ text: 'Select', value: 'select' }],
  },
  {
    inputType: 'select',
    name: 'countryCode',
    label: 'Country',
    fullWidth: false,
    customValue: 'IE',
    customWidth: '33%',
    hidden: true,
    options: [{ text: 'Ireland', value: 'IE' }],
  },
];
