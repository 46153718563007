import React, { useEffect } from 'react';
import Form from '@/components/pageBuilder/Elements/Form';
import { ContentWrapper, Title } from './RegisterForms.styles';
import { useFormHandler } from './useFormHandler';
import { IFormProps } from './RegisterForms.types';

export const ContactDetails = (props: IFormProps) => {
  const { form, handleInputChange, handleInputBlur } = useFormHandler('contact');
  return (
    <ContentWrapper>
      <Title formTheme={props.formTheme}>Contact details</Title>
      <Form
        customInputLabelSize="14px"
        formInputs={form}
        formValidation={isValid => {
          props.validCallback(isValid, 'contact');
        }}
        onInputChange={handleInputChange}
        onInputBlur={handleInputBlur}
        shouldHaveFullWidthOnMobile={true}
        formSubmit={form => props.onSubmit && props.onSubmit(form, 'contact')}
        formTheme={props.formTheme}
      />
    </ContentWrapper>
  );
};
