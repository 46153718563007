import React from 'react';
import axios from 'axios';
import { useWindowSize } from 'usehooks-ts';
import { Button } from '@uk-source-web/button';
import { Heading } from '@uk-source-web/heading';
import { Paragraph } from '@uk-source-web/paragraph';
import { useAppSelector } from '@/store';
import { SWBasketProps } from './SWBasket.types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyBasketIcon } from '../../../../images/icon/empty-basket.svg';

import { checkForZeroOrNull } from '@/helpers/nullOrZero';
import { useModalContext } from '@/hooks/modal/useModal';
import { OPCOOptions } from '@/types/Services.types';
import { ProductOrderBasketProduct, productOrder } from '@/services/product.services';

import BasketItem from './BasketItem';
import BasketPrice from './BasketPrice';
import { PRODUCT_BUNDLE_TYPENAME, transformToItemOrder } from './SWBasket.helper';

import SWBasketStyles, {
  BasketButtonsInner,
  ContainerHeaderStyles,
  EmptyBasket,
  SWBakestTotalPrices,
  SWBasketContainer,
  SWBasketHeader,
  SWBasketSummary,
  SWBasketSummaryInner,
  SWBasketTotal,
  WrapperBasketButtons,
} from './SWBasket.styles';
import handleTranslation from '@/helpers/handleTranslation';
import usingOpcoUrl from '@/helpers/prefixHelper';

const OPCO = process.env.GATSBY_OPCO;

const SWBasket = ({ name, continueShoppingButton, requestCallbackButton, goToCheckoutButton }: SWBasketProps) => {
  const hasUpfrontPrice = OPCO === OPCOOptions.IRELAND;

  const { t } = useTranslation();
  const { width } = useWindowSize();

  const { toggleModal, setErrorInfo } = useModalContext();

  const { user } = useAppSelector(({ userSession }) => userSession);
  const { userBasket } = useAppSelector(({ userBasket: { data } }) => ({
    userBasket: data,
  }));

  const yourOrderSummary = handleTranslation('Your Order Summary', 'Carrinho de compras');
  const monthlyTrans = handleTranslation('Monthly', 'Mensal');
  const totalCost = handleTranslation('Total cost', 'Valor total');
  const completePurchase = handleTranslation('Complete purchase', 'Completar compra');
  const emptyBasket = handleTranslation('Your basket is empty', 'O seu carrinho está vazio');
  const emptyBasketSubtitle = handleTranslation(
    'The items you add will appear here',
    'Os itens que você adicionar aparecerão aqui'
  );
  const accountTrans = handleTranslation('Account', 'Conta');

  const postProductOrder = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    toggleModal(true, 'LoadingCheckout');

    if (
      user?.organizationId &&
      user?.userId &&
      userBasket &&
      userBasket.items.length > 0 &&
      !userBasket.items.find(item => !item.dxlInformation?.periods[0].uuid)
    ) {
      const products = userBasket.items.flatMap(item => {
        const { quantity } = item;
        if (
          item.__typename === PRODUCT_BUNDLE_TYPENAME &&
          item.productsFreeOfCharge &&
          item.productsFreeOfCharge.length > 0
        ) {
          return [
            transformToItemOrder(item, undefined),
            ...item.productsFreeOfCharge.map(subItem => transformToItemOrder(subItem, quantity)),
          ];
        } else if (item.__typename === PRODUCT_BUNDLE_TYPENAME && !item.productsFreeOfCharge) {
          return transformToItemOrder(item, undefined);
        }
        return transformToItemOrder(item, undefined);
      }) as ProductOrderBasketProduct[];

      const res = await productOrder(
        {
          basket: {
            organizationId: user?.organizationId,
            userId: user?.userId,
            // INFO: The order sent to the marketplace need to be checked if it's being calculated right, because here
            // we are just sending the prices to cost one, maybe we should send every unit price related to the product.
            products,
          },
        },
        user.csrfToken || '',
        setErrorInfo,
        toggleModal
      );
      if (res?.data.needCreditVetting) {
        return usingOpcoUrl('/marketplace/credit-vetting-form', true);
      } else if (res?.data.id) {
        window.location.href = `${process.env.GATSBY_APPDIRECT_BASE_URL}/partner/login/${process.env.GATSBY_APPDIRECT_TEMP_CONFIG_ID}?login_hint=OPCO:${process.env.GATSBY_OPCO},USERNAME:${user.username}&stateToken=/checkout/cart&acr_values=${process.env.GATSBY_ACR_VALUE_CHECKOUT}`;
      }
    }
  };

  const montlyCostValue = checkForZeroOrNull(userBasket.total?.monthly?.gross, 'NA');
  const upfrontCostValue = checkForZeroOrNull(userBasket.total?.upfront?.gross, 'NA');

  return (
    <SWBasketStyles>
      <SWBasketContainer {...ContainerHeaderStyles(width)}>
        <SWBasketHeader>
          <Heading level={3} justify={'center'} text={name} />
          <Paragraph size={2}>
            <span>{accountTrans}</span>
            <br />
            <b>{user?.selectedAccount?.customerAccountId}</b>
          </Paragraph>
        </SWBasketHeader>
      </SWBasketContainer>
      {userBasket?.items.length > 0 ? (
        <>
          <SWBasketContainer backgroundColor="#F4F4F4">
            <SWBasketSummary>
              <SWBasketSummaryInner>
                <Heading level={2} size={2} weight={3} text={yourOrderSummary} />
                {userBasket.items.map(product => {
                  if (product.__typename === PRODUCT_BUNDLE_TYPENAME) {
                    return (
                      <>
                        <BasketItem key={product.id} {...product} />
                        {product?.productsFreeOfCharge?.map(productFreeOfCharge => (
                          <BasketItem
                            key={productFreeOfCharge.id}
                            {...productFreeOfCharge}
                            increaseDecreaseAct={false}
                            quantity={product.quantity}
                          />
                        ))}
                      </>
                    );
                  }
                })}
              </SWBasketSummaryInner>
            </SWBasketSummary>
          </SWBasketContainer>
          <SWBasketContainer backgroundColor="#FFFFFF">
            <SWBasketTotal>
              <Heading level={4} weight={3}>{totalCost}</Heading>
              <SWBakestTotalPrices>
                {hasUpfrontPrice && <BasketPrice label={t('upfront')} labelAlign="right" value={upfrontCostValue} />}
                <BasketPrice label={monthlyTrans} labelAlign="right" value={montlyCostValue} />
              </SWBakestTotalPrices>
            </SWBasketTotal>
            <WrapperBasketButtons>
              <BasketButtonsInner>
                {OPCO !== OPCOOptions.PORTUGAL && (
                  <Button
                    text={continueShoppingButton.label}
                    appearance="alt1"
                    href={continueShoppingButton.url}
                    data-selector="MS365-BundleConfig-OrderSummary-Next"
                  />
                )}
                {requestCallbackButton && (
                  <Button
                    text={requestCallbackButton.label}
                    appearance="alt1"
                    href={requestCallbackButton?.url}
                    data-selector="MS365-BundleConfig-OrderSummary-RequestCallback"
                  />
                )}
              </BasketButtonsInner>

              <Button
                id="basket-button-complete-purchase"
                state={userBasket && userBasket.items?.length > 0 ? undefined : 'disabled'}
                text={completePurchase}
                appearance={goToCheckoutButton.rank}
                onClick={postProductOrder}
              />
            </WrapperBasketButtons>
          </SWBasketContainer>
        </>
      ) : (
        <SWBasketContainer backgroundColor="#F4F4F4">
          <SWBasketSummary>
            <EmptyBasket>
              <Heading size={2} level={3} weight={3}>
                {emptyBasket}
              </Heading>
              <Paragraph weight={1}>{emptyBasketSubtitle}</Paragraph>
              <WrapperBasketButtons>
                <BasketButtonsInner>
                  {OPCO !== OPCOOptions.PORTUGAL && (
                    <Button
                      text={continueShoppingButton.label}
                      appearance="primary"
                      href={continueShoppingButton.url}
                      data-selector="MS365-BundleConfig-OrderSummary-Next"
                    />
                  )}
                  {requestCallbackButton && (
                    <Button
                      text={requestCallbackButton.label}
                      appearance="alt1"
                      href={requestCallbackButton?.url}
                      data-selector="MS365-BundleConfig-OrderSummary-RequestCallback"
                    />
                  )}
                </BasketButtonsInner>
              </WrapperBasketButtons>
            </EmptyBasket>
          </SWBasketSummary>
        </SWBasketContainer>
      )}
    </SWBasketStyles>
  );
};

export default SWBasket;
