import { rem } from 'polished';
import { styled } from 'styled-components';

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${rem('5px')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column-reverse;
    margin: ${rem('20px')} 0;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModelContent = styled.div`
  max-width: 660px;
  width: 100%;
  z-index: 1001;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 90%;
  }
`;
