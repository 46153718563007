import styled from 'styled-components';
import { rem } from 'polished';

export const BusinessSizeTitle = styled.div<{ formTheme?: 'white' | 'black' }>`
  font-size: ${rem('40px')};
  height: fit-content;
  align-self: center;
  width: ${rem('1030px')};
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 700;
  line-height: ${rem('48px')};
  text-align: center;
  color: ${({ formTheme }) => (formTheme === 'white' ? 'black' : 'white')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 90%;
  }
`;

export const BusinessSizeSubTitle = styled.div<{ customFontSize?: number; formTheme?: 'white' | 'black' }>`
  font-size: ${({ customFontSize }) => customFontSize || 20}px;
  font-weight: 400;
  line-height: ${rem('28px')};
  text-align: center;
  color: ${({ formTheme }) => (formTheme === 'white' ? 'black' : 'white')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('24px')};
  }
`;

export const BusinessSizeWrapper = styled.div`
  padding: ${rem('20px')};
  width: ${rem('770px')};
  max-width: 100%;
  background-color: #f2f2f2;
  [data-component-name='Button'] {
    border: solid 1px #0d0d0d;
    height: ${rem('68px')};
    background: #fff;
  }
  [data-component-name='Button']:hover {
    border: solid ${rem('2px')} #0d0d0d;
    background: #fff;
    font-size: ${rem('20px')};
    border: solid ${rem('2px')} #0d0d0d;
    color: #0d0d0d;
    cursor: pointer;
  }
`;
