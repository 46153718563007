// src/components/TermsAndConditionsModal.js
import React, { useRef, useState } from 'react';
import {
  ModalOverlay,
  ModalContainer,
  Title,
  Section,
  Paragraph,
  Subtitle,
  RedLink,
  ParagraphOption,
  ParagraphPrefix,
  ParagraphWrapper,
  TermsAndConditionsWrapper,
  ButtonWrapper,
  CloseButton,
} from './TermsAndConditions.styles';
import { Button } from '@legacy-vfuk/core-button';

const TermsAndConditionsModal = ({ onAccept }: { onAccept: () => void }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const termsAndConditionsRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (termsAndConditionsRef.current) {
      setIsScrolled(termsAndConditionsRef.current.scrollTop >= 100);
    }
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <CloseButton onClick={onAccept}>X</CloseButton>
        <Title>Vodafone Business Marketplace</Title>
        <Subtitle>Terms & Conditions</Subtitle>
        <TermsAndConditionsWrapper ref={termsAndConditionsRef} onScroll={handleScroll}>
          <Section>1. The Service - Overview</Section>
          <Paragraph>
            {`1.1 The Vodafone Business Marketplace is a platform which provides the Customer with access to purchase a
          variety of Products (“Service”) powered by AppDirect.`}
          </Paragraph>

          <Section>2. Service Term Structure</Section>
          <ParagraphWrapper>
            <ParagraphPrefix>2.1</ParagraphPrefix>
            <Paragraph>
              The following documents govern Vodafone's supply of the Service and form part of the Agreement, applying
              in the order of precedence set out in the General Terms:
            </Paragraph>
          </ParagraphWrapper>
          <ParagraphOption>
            (a) the Commercial Terms or Order, which confirms the Products selected by or for the Customer;
          </ParagraphOption>
          <ParagraphOption>(b) these Service Specific Terms;</ParagraphOption>
          <ParagraphOption>
            (c) the General Terms available on{' '}
            <RedLink href="https://n.vodafone.ie/terms/business" target="_blank">
              n.vodafone.ie/terms/business
            </RedLink>
            ; and
          </ParagraphOption>
          <ParagraphOption>
            (d) any applicable statement of works, policies and/or guidelines, as provided from time to time by
            Vodafone.
          </ParagraphOption>

          <Section>3. The Service</Section>
          <ParagraphWrapper>
            <ParagraphPrefix>3.1</ParagraphPrefix>
            <Paragraph>
              {`In order to access, and use the Vodafone Business Marketplace, Customer must create, or authorise Vodafone
          to create on its behalf, a Vodafone Business Marketplace Account and accept these Service Specific Terms. If
          the Customer does not accept these Service Specific Terms, the Customer may not use or access the Vodafone
          Business Marketplace.`}
            </Paragraph>
          </ParagraphWrapper>
          <ParagraphWrapper>
            <ParagraphPrefix>3.2</ParagraphPrefix>
            <Paragraph>
              {`The Products offered on the Vodafone Business Marketplace are made available by Third Party Developers
          and, as such, neither AppDirect nor Vodafone are responsible for such Third Party Developer Products.`}
            </Paragraph>
          </ParagraphWrapper>

          <ParagraphWrapper>
            <ParagraphPrefix>3.3</ParagraphPrefix>
            <Paragraph>{`Customer makes final payment to Vodafone in exchange for the supply of Products, and the Third Party Developer has no       claims against the Customer in this respect.`}</Paragraph>
          </ParagraphWrapper>
          <ParagraphWrapper>
            <ParagraphPrefix>3.4</ParagraphPrefix>
            <Paragraph>{`Purchases of access to Third Party Developer Products are subject to that Third Party Developer’s Terms of Service, which the Customer will have the opportunity to review and accept before processing its transaction, if they are not detailed in these Service Specific Terms. The Customer shall agree to abide by the applicable Third Party Developer’s Terms of Service, and must accept the respective Third Party Developer’s Terms of Service with no alteration or amendment.`}</Paragraph>
          </ParagraphWrapper>

          <ParagraphWrapper>
            <ParagraphPrefix>3.5</ParagraphPrefix>
            <Paragraph>{`The Products are available to purchase in accordance with the payment periods set by each Third Party Developer, which cannot be changed, and are as follows:`}</Paragraph>
          </ParagraphWrapper>
          <ParagraphOption>{`(a) a monthly contract (“Monthly Subscription”),`}</ParagraphOption>
          <ParagraphOption>{`(b) a 12 month contract paying an upfront lump sum (“Upfront Subscription”),`}</ParagraphOption>
          <ParagraphOption>{`(c) a 12 or 24 month contract paying equal monthly fee instalments and, where applicable, any upfront lump sum (“Annual Subscription”).`}</ParagraphOption>

          <ParagraphWrapper>
            <ParagraphPrefix>3.6</ParagraphPrefix>
            <Paragraph>{`Product purchases will appear on the Customer’s Vodafone bill as an additional product category called Software and Services.`}</Paragraph>
          </ParagraphWrapper>
          <ParagraphWrapper>
            <ParagraphPrefix>3.7</ParagraphPrefix>
            <Paragraph>{`A detailed breakdown of purchases are available via Customer’s monthly bill, Customer’s e-care account and Customer’s My       Vodafone App as applicable.`}</Paragraph>
          </ParagraphWrapper>
          <ParagraphWrapper>
            <ParagraphPrefix>3.8</ParagraphPrefix>
            <Paragraph>{`Territory: The Vodafone Business Marketplace is provided by or on behalf of Vodafone and intended for use in the Republic of       Ireland only.`}</Paragraph>
          </ParagraphWrapper>
        </TermsAndConditionsWrapper>
        <ButtonWrapper>
          <Button
            text="Accept"
            onClick={onAccept}
            appearance={!isScrolled ? 'secondary' : 'primary'}
            width="full"
            state={!isScrolled ? 'disabled' : undefined}
          />
        </ButtonWrapper>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default TermsAndConditionsModal;
