import React, { useEffect } from 'react';
import { ContentWrapper, Title } from './RegisterForms.styles';
import Form from '@/components/pageBuilder/Elements/Form';
import { useFormHandler } from './useFormHandler';
import { Spacer } from '../RegisterAccount.styles';
import { IFormProps } from './RegisterForms.types';

export const CompanyDetails = (props: IFormProps) => {
  const { form, handleInputChange, handleInputBlur } = useFormHandler('company');

  return (
    <ContentWrapper>
      <Title>Company details</Title>
      <Form
        customInputLabelSize="14px"
        formInputs={form}
        onInputChange={handleInputChange}
        onInputBlur={handleInputBlur}
        shouldHaveFullWidthOnMobile={true}
        formValidation={isValid => {
          props.validCallback(isValid, 'company');
        }}
        formSubmit={form => props.onSubmit && props.onSubmit(form, 'company')}
        formTheme={props.formTheme}
      />
      <Spacer h={30} w={100} />
    </ContentWrapper>
  );
};
