import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Spacer, ButtonWrapper, RegisterAccountWrapper } from './RegisterAccount.styles';
import { BusinessSizeTitle, BusinessSizeSubTitle, BusinessSizeWrapper } from './BusinessSize.styles';
import { SimpleGrid } from '@uk-source-web/simple-grid';
import { Button } from '@uk-source-web/button';
import BusinessSizeModal from './BusinessSizeModal/BusinessSizeModal';
import { TitleWrapper } from '../SWFooter/SWFooter.styles';

export interface IBusinessSizeProps {
  title: string;
  subTitle: string;
  formTheme?: 'white' | 'black';
  onChange: (size: number) => void;
}

export const BusinessSize: FC<IBusinessSizeProps> = ({ title, subTitle, formTheme, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBussinessSize, setSelectedBussinessSize] = useState<number | null>(null);

  const handleChange = (size: number) => {
    setSelectedBussinessSize(size);
    onChange(size);
  };

  return (
    <>
      <BusinessSizeTitle formTheme={formTheme}>{title}</BusinessSizeTitle>
      <Spacer h={120} />
      <BusinessSizeSubTitle customFontSize={28} formTheme={formTheme}>
        {subTitle}
      </BusinessSizeSubTitle>
      <Spacer h={50} />
      <BusinessSizeWrapper>
        <SimpleGrid
          columns={{
            sm: 1,
            md: 2,
          }}
          verticalAlign="stretch"
          spacing={2}
        >
          <ButtonWrapper selected={selectedBussinessSize === 1}>
            <Button text="0-4 employees" appearance="alt1" width="full" onClick={() => handleChange(1)} />
          </ButtonWrapper>
          <ButtonWrapper selected={selectedBussinessSize === 2}>
            <Button text="5-25 employees" appearance="alt1" width="full" onClick={() => handleChange(2)} />
          </ButtonWrapper>
        </SimpleGrid>
        <Spacer h={10} />
        <ButtonWrapper selected={selectedBussinessSize === 3}>
          <Button
            text="More than 25 employees"
            appearance="alt1"
            width="full"
            onClick={() => {
              setIsOpen(true);
              handleChange(3);
            }}
          />
        </ButtonWrapper>
      </BusinessSizeWrapper>
      <BusinessSizeModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
