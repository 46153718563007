import styled from '@emotion/styled';
import { rem } from 'polished';

export const RegisterAccountWrapper = styled.div<{ formTheme?: 'white' | 'black' }>`
  width: 100%;
  height: 100%;
  font-family: 'VodafoneRg';
  background-color: ${({ formTheme }) => (formTheme === 'white' ? '#fff' : '#222')};
  color: ${({ formTheme }) => (formTheme === 'white' ? 'black' : 'white')};
  top: 0;
  padding: ${rem('80px')} 0 0 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  flex-direction: column;
`;

export const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${rem('1030px')};
  width: 100%;
`;

export const ContentWrapper = styled.div<{ formTheme?: 'white' | 'black' }>`
  display: flex;
  max-width: ${rem('640px')};
  width: 100%;
  height: fit-content;
  align-items: center;
  flex-direction: column;
  color: ${({ formTheme }) => (formTheme === 'white' ? 'black' : 'white')};
`;

export const RegisterTitle = styled.div<{ formTheme?: 'white' | 'black' }>`
  font-size: ${rem('40px')};
  height: fit-content;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 700;
  line-height: ${rem('48px')};
  text-align: center;
  color: ${({ formTheme }) => (formTheme === 'white' ? 'black' : 'white')};
`;

export const RegisterSubtitle = styled.div<{ customFontSize?: number; formTheme?: 'white' | 'black' }>`
  font-size: ${({ customFontSize }) => customFontSize || 20}px;
  font-weight: 400;
  line-height: ${rem('28px')};
  text-align: center;
  color: ${({ formTheme }) => (formTheme === 'white' ? 'black' : 'white')};
`;

export const Spacer = styled.div<{ h?: number; w?: number }>`
  height: ${({ h }) => h || 0}px;
  width: ${({ w }) => w || 0}px;
`;

export const BottomButtonsWrapper = styled.div<{ formTheme?: 'white' | 'black' }>`
  margin: ${rem('50px')} 0 ${rem('80px')} 0;
  width: 100%;
  max-width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ formTheme }) => (formTheme === 'white' ? 'black' : 'white')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column-reverse;
    gap: ${rem('20px')};
  }
`;

export const ButtonWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  [data-component-name='Button'] {
    border: ${({ selected }) => (selected ? 'solid 2px #00697C' : 'none')};
  }
`;
