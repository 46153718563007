import { FC } from 'react';
import Button from '@legacy-vfuk/core-button';
import {
  AbsoluteIcon,
  RedCheckmarkWrapper,
  SuccessPageAlertWrapper,
  SuccessPageButtonWrapper,
  SuccessPageSubtitle,
  SuccessPageTitle,
  SuccessPageWrapper,
} from './SuccessPage.styles';
import { CheckmarkTexture, RedCheckmark } from './svgs/RedCheckmark';
import { Spacer } from './RegisterAccount.styles';
import { AlertNotification } from '@uk-source-web/alert-notification';
import { useModalContext } from '@/hooks/modal/useModal';

const SuccessPage: FC<any> = () => {
  const { toggleModal } = useModalContext();

  return (
    <SuccessPageWrapper>
      <Spacer h={60} />
      <RedCheckmarkImage />
      <Spacer h={60} />
      <SuccessPageTitle>Your information has been submitted</SuccessPageTitle>
      <Spacer h={40} />
      <SuccessPageSubtitle>
        An email to complete your account registration has been sent to your Inbox.
      </SuccessPageSubtitle>
      <SuccessPageSubtitle>Please validate it before loggin in.</SuccessPageSubtitle>
      <Spacer h={50} />
      <SuccessPageButtonWrapper>
        <Button
          appearance="primary"
          text={'Login'}
          onClick={() => {
            toggleModal(true, 'Login');
          }}
        />
      </SuccessPageButtonWrapper>
      <SuccessPageAlertWrapper>
        <AlertNotification
          state="info"
          heading={{
            text: 'If you have not received an email in your inbox please call 1907 for further assistance',
            level: 2,
          }}
        />
      </SuccessPageAlertWrapper>
    </SuccessPageWrapper>
  );
};

const RedCheckmarkImage = () => {
  return (
    <RedCheckmarkWrapper>
      <AbsoluteIcon>
        <CheckmarkTexture />
      </AbsoluteIcon>
      <AbsoluteIcon x={15} y={1}>
        <RedCheckmark />
      </AbsoluteIcon>
    </RedCheckmarkWrapper>
  );
};

export default SuccessPage;
