import { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby';
import { rem } from 'polished';

import { Accordion, Box, Flex, RadioGroup, Stack } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from '@legacy-vfuk/core-button';
import Container from '@/components/Global/Container';

import { CancelButton, HeadingWrapper, NextButton, headingFlexStyles } from './SWConfigureBundleOptions.styles';
import { NextStepProps, SWConfigureBundleOptionsProps } from './SWConfigureBundleOptions.types';
import BundleOption from './BundleOption';
import usingOpcoUrl from '@/helpers/prefixHelper';
import handleTranslation from '@/helpers/handleTranslation';

const SWConfigureBundleOptions = ({
  id,
  contentful_id,
  productSlug,
  sectionTitle,
  options,
}: SWConfigureBundleOptionsProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedSecondaryOption, setSelectedSecondaryOption] = useState<string>('');
  const [nextStep, setNextStep] = useState<NextStepProps>({ isEnabled: false });

  const sectionId = `${id}-${contentful_id}`;
  const back = handleTranslation('Back', 'Voltar');
  const next = handleTranslation('Next', 'Seguinte');

  useEffect(() => {
    const hasSecondaryChoices = !!options.filter(option => {
      if (
        selectedOption === `${option.id}-${option.contentful_id}` &&
        option.secondaryOptions &&
        option.secondaryOptions.length > 0
      ) {
        return option;
      }
    })[0];

    if (hasSecondaryChoices && selectedSecondaryOption) {
      const option = options
        .filter(option => `${option.id}-${option.contentful_id}` === selectedOption)[0]
        .secondaryOptions?.filter(option => `${option.id}-${option.contentful_id}` === selectedSecondaryOption)[0];
      const destination = {
        destinationLink: option?.destinationLink,
        destinationType: option?.destinationType,
      };

      setNextStep({ destination, isEnabled: !!destination.destinationLink && !!destination.destinationType });
    }

    if (!hasSecondaryChoices && selectedOption) {
      setSelectedSecondaryOption('');
      const { destinationLink, destinationType } = options.filter(
        option => `${option.id}-${option.contentful_id}` === selectedOption
      )[0];
      const destination = {
        destinationLink,
        destinationType,
      };

      setNextStep({ destination, isEnabled: !!destination.destinationLink && !!destination.destinationType });
    }

    if (!selectedOption && !selectedSecondaryOption) {
      setNextStep({ isEnabled: false });
    }
  }, [selectedOption, selectedSecondaryOption]);

  return (
    <Box
      as="section"
      id={sectionId}
      backgroundColor="white"
      py={{ base: rem('17px'), md: rem('116px'), lg: rem('152px') }}
      h="full"
      style={{ minHeight: '100vh' }}
    >
      <Container>
        <HeadingWrapper direction={{ base: 'column', md: 'row' }} textAlign="center">
          <CancelButton>
            <Button
              icon={{
                name: 'chevron-left',
                justify: 'left',
              }}
              text={back}
              href={usingOpcoUrl(
                withPrefix(productSlug ? `marketplace/product-list/${productSlug}` : 'marketplace/office-anywhere')
              )}
              appearance="alt1"
            />
          </CancelButton>

          <Flex textAlign="center" direction="column" justify="center" {...headingFlexStyles}>
            {documentToReactComponents(JSON.parse(sectionTitle.raw))}
          </Flex>

          <CancelButton display={{ base: 'none', md: 'flex' }} visibility="hidden">
            <Button
              icon={{
                name: 'chevron-right',
                justify: 'right',
              }}
              text={next}
              appearance="alt1"
            />
          </CancelButton>
        </HeadingWrapper>

        <Flex align="center" direction="column" mt={{ base: rem('18px'), md: 10, lg: rem('34px') }}>
          <Stack align="center" maxW={rem('780px')} spacing={{ base: 13, md: rem('103px') }}>
            <RadioGroup onChange={setSelectedOption} value={selectedOption}>
              <Accordion>
                <Stack spacing={{ base: rem('30px'), md: 12 }}>
                  {options?.map(option => (
                    <BundleOption
                      key={`${option.id}-${option.contentful_id}`}
                      option={option}
                      sectionId={sectionId}
                      selectedOption={selectedOption}
                      selectedSecondaryOption={selectedSecondaryOption}
                      setSelectedOption={option => setSelectedOption(option)}
                      setSelectedSecondaryOption={option => setSelectedSecondaryOption(option)}
                    />
                  ))}
                </Stack>
              </Accordion>
            </RadioGroup>

            <NextButton isEnabled={nextStep.isEnabled}>
              <Button
                text={next}
                {...(!nextStep.isEnabled && {
                  state: 'disabled',
                })}
                href={usingOpcoUrl(
                  nextStep.destination?.destinationType === 'internal' && nextStep.destination?.destinationLink
                    ? withPrefix(nextStep.destination.destinationLink)
                    : nextStep.destination?.destinationLink
                )}
                dataSelectorPrefix="next for bundles"
                data-selector="MS365-Questionaire-NextButton"
              />
            </NextButton>
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
};

export default SWConfigureBundleOptions;
