import { rem } from 'polished';
import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  background-color: #0d0d0d4d;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ModalContainer = styled.div`
  width: ${rem('1180px')};
  padding: ${rem('50px')} ${rem('100px')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: ${rem('30px')} ${rem('20px')};
  }
  height: ${rem('810px')};
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #fff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${rem('0px')} ${rem('2px')} ${rem('8px')} ${rem('0px')} #00000029;
  border-radius: ${rem('10px')};
  color: #000;
`;

export const TermsAndConditionsWrapper = styled.div`
  width: ${rem('980px')};
  height: ${rem('530px')};
  max-width: 100%;
  max-height: 90%;
  background-color: #fff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: 'Vodafone Lt';
  font-size: ${rem('40px')};
  font-weight: 700;
  line-height: ${rem('45.84px')};
  text-align: center;
  margin: 0;
`;
export const Subtitle = styled.p`
  font-family: 'Vodafone Lt';
  font-size: ${rem('40px')};
  font-weight: 400;
  line-height: ${rem('45.84px')};
  text-align: center;
  margin: 0;
  margin-bottom: ${rem('20px')};
`;

export const Section = styled.h3`
  align-self: flex-start;
  font-family: 'Vodafone Rg';
  font-weight: 400;
  font-size: ${rem('24px')};
  line-height: ${rem('27.55px')};
  color: #e60000;
  margin: ${rem('20px')} 0;
`;

export const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ParagraphPrefix = styled.p`
  font-family: 'Vodafone Lt';
  font-size: ${rem('20px')};
  font-weight: 400;
  line-height: ${rem('22.96px')};
  margin-right: ${rem('10px')};
`;
export const Paragraph = styled.p<{ removeMargin?: boolean }>`
  font-family: 'Vodafone Lt';
  font-size: ${rem('20px')};
  font-weight: 400;
  line-height: ${rem('22.96px')};
  text-align: left;
  align-self: flex-start;
`;

export const ParagraphOption = styled(Paragraph)`
  margin: 0;
  margin-left: ${rem('30px')};
`;

export const RedLink = styled.a`
  color: #e60000;
`;

export const ButtonWrapper = styled.div`
  width: ${rem('382px')};
  max-width: 100%;
  margin-top: ${rem('40px')};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: ${rem('30px')};
  right: ${rem('30px')};
  font-size: ${rem('40px')};
  font-weight: 400;
  line-height: ${rem('22.96px')};
  color: #000;
  text-shadow: none;
`;
