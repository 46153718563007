import { rem } from 'polished';
import styled from 'styled-components';
import { IFormInput } from './Form.types';

export const InputContainer = styled.div<{
  fullWidth: boolean | undefined;
  formTheme?: 'white' | 'black';
  labelType?: IFormInput['labelType'];
  customWidth?: string;
  disabled?: boolean;
  hidden?: boolean;
  fullWidthOnMobile?: boolean;
  customLabelSize?: string;
  customFontSize?: string;
}>`
  ${({ hidden }) => {
    if (hidden) {
      return `display: none;`;
    } else {
      return `display: initial;`;
    }
  }}

  [class*='AddressPickerstyle__AddressPickerWrapper-sc'] {
    input {
      padding-right: 0px;
    }
  }

  margin: ${rem('10px')} 0;
  padding: 0 ${rem('10px')};
  [class*='Checkboxstyle__Checkbox-sc'] {
    background-color: transparent;
  }
  [class*='Checkboxstyle__CheckboxLabel-sc'] {
    font-size: ${rem('14px')};
    ${({ customFontSize }) => {
      if (customFontSize) {
        return `font-size: ${customFontSize};`;
      }
    }}
  }

  [class*='Radiostyle__LabelText-sc'] {
    color: ${({ formTheme }) => (formTheme === 'white' ? '#0D0D0D' : 'var(--white)')};
    ::before {
      background-color: transparent;
    }
  }
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  li {
    color: ${({ formTheme }) => (formTheme === 'white' ? '#0D0D0D' : 'var(--white)')};
  }
  [class*='FieldWrapperstyle__Label'] {
    ${({ formTheme, labelType }) => {
      if (labelType === 'transparent') {
        return `color: transparent;`;
      } else if (labelType == 'hidden') {
        return 'display: none;';
      }
      return `color: ${formTheme === 'white' ? '#0D0D0D' : 'var(--white)'};`;
    }}
    ${({ customLabelSize }) => {
      if (customLabelSize) {
        return `font-size: ${customLabelSize};`;
      }
    }}
  }

  ${({ fullWidth, customWidth, fullWidthOnMobile }) => {
    if (!fullWidthOnMobile) {
    }
    if (fullWidth) {
      return `width: 100%;`;
    } else if (customWidth) {
      return `width: ${customWidth};`;
    }
    return `width: 50%;`;
  }}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    ${({ fullWidthOnMobile }) => {
      if (fullWidthOnMobile) {
        return `width: 100%;`;
      }
    }}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    [class*='FieldWrapperstyle__FieldWrapper-sc'] {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const InputLabel = styled.div<{
  formTheme?: 'white' | 'black';
  labelType?: IFormInput['labelType'];
  customLabelSize?: string;
}>`
  font-size: ${rem('20px')};
  margin: 0 0 ${rem('10px')} 0;

  ${({ formTheme, labelType }) => {
    if (labelType == 'transparent') {
      return `color: transparent;`;
    } else if (labelType == 'hidden') {
      return 'display: none';
    }
    return `color: ${formTheme === 'white' ? '#0D0D0D' : 'var(--white)'};`;
  }}
  ${({ customLabelSize }) => {
    if (customLabelSize) {
      return `font-size: ${customLabelSize};`;
    }
  }}
`;
