import { Paragraph } from '@uk-source-web/paragraph';
import React, { FC } from 'react';
import { Button } from '@uk-source-web/button';
import { ModalButtonWrapper, ModalOverlay, ModelContent } from './BusinessSizeModal.styles';
import { AlertNotification } from '@uk-source-web/alert-notification';
import { Link } from '@uk-source-web/link';

export type BusinessSizeModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const BusinessSizeModal: FC<BusinessSizeModalProps> = ({ isOpen, setIsOpen }) => {
 
  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModelContent>
            <AlertNotification state="info" heading={{ text: 'More Than 25 employee', level: 2 }}>
              <Paragraph weight={2}>
                If your company is this size, please contact our business specialists who will help you set up your
                account. Get in touch by calling us on [Phone number] or by <br />
                filling out the form.
              </Paragraph>
              <ModalButtonWrapper>
                <Button text="Close" appearance="alt1" onClick={() => setIsOpen(false)} />
                <Link href='https://n.vodafone.ie/business/business-call-back.html'>
                <Button text="Get in touch" appearance="secondary"/>
                </Link>
              </ModalButtonWrapper>
            </AlertNotification>
          </ModelContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default BusinessSizeModal;
