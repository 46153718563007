import styled from '@emotion/styled';
import { rem } from 'polished';

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Title = styled.div<{ formTheme?: 'white' | 'black' }>`
  width: 100%;
  color: ${({ formTheme }) => (formTheme === 'white' ? 'black' : 'white')};
  padding: 0 ${rem('8px')};
  margin: ${rem('10px')} 0;
  font-size: ${rem('20px')};
  font-weight: 700;
  line-height: ${rem('28px')};
  text-align: left;
`;
export const FormParagraph = styled.div<{ size: number; formTheme?: 'white' | 'black' }>`
  font-size: ${props => props.size}px;
  margin: 0 ${rem('10px')};
  color: ${({ formTheme }) => (formTheme === 'white' ? 'black' : 'white')};
  font-family: 'Vodafone Rg';
  font-weight: 400;
  line-height: ${rem('28px')};
  text-align: center;
  width: 100%;
`;
export const UnderlinedParagraph = styled.a`
  text-decoration: underline;
  color: #8c2b4b;
  font-size: ${rem('20px')};
  font-weight: 700;
  line-height: ${rem('28px')};
  text-align: center;
`;

export const FormParagraphWrapper = styled.div`
  text-align: center;
  width: ${rem('1200px')};
  max-width: 95vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
