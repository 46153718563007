import { FC, useState, useCallback, useEffect } from 'react';
import {
  BottomButtonsWrapper,
  ContentWrapper,
  RegisterAccountWrapper,
  RegisterSubtitle,
  RegisterTitle,
  Spacer,
  StepperWrapper,
  TitleWrapper,
} from './RegisterAccount.styles';
import { ContactDetails } from './RegisterForms/ContactDetails';
import { CompanyDetails } from './RegisterForms/CompanyDetails';
import Button from '@legacy-vfuk/core-button';
import SuccessPage from './SuccessPage';
import { FormStateProvider } from './RegisterForms/FormContext';
import { ButtonProps } from '@uk-source-web/button';
import { IFormState } from '@/components/pageBuilder/Elements/Form/Form.types';
import { registerUser, UserRegistrationPayload } from '@/services/user.services';
import { useModalContext } from '@/hooks/modal/useModal';
import { HorizontalStepper, HorizontalStepperProps } from '@uk-source-web/horizontal-stepper';
import { BusinessSize } from './BusinessSize';
import { BillingDetails } from './RegisterForms/BillingDetails';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';

export type RegisterAccountProps = {
  id: string;
  name?: string;
  __typename: 'ContentfulNewAccountRegistrationFlow';
  theme?: 'white' | 'black';
  businessSizeTitle?: string;
  businessSizeSubTitle?: string;
  contactDetailsTitle?: string;
  contactDetailsSubTitle?: string;
  companyDetailsTitle?: string;
  companyDetailsSubTitle?: string;
  billingDetailsTitle?: string;
  billingDetailsSubTitle?: string;
};

type RegisterForm = {
  company: IFormState | null;
  contact: IFormState | null;
  billing: IFormState | null;
};

const RegisterAccount: FC<RegisterAccountProps> = ({
  billingDetailsSubTitle = 'Finish the form by completing the billing information',
  billingDetailsTitle = 'New account registration',
  businessSizeSubTitle = 'What is the size of your company?',
  businessSizeTitle = 'First, let us know about your business',
  companyDetailsSubTitle = 'Now, we will need further details about your company',
  companyDetailsTitle = 'New account registration',
  contactDetailsSubTitle = 'Now, we will need your personal details. Then, we will ask further information about your company',
  contactDetailsTitle = 'New account registration',
  theme = 'white',
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const steps: HorizontalStepperProps['steps'] = [
    {
      title: 'Contact details',
      srText: 'Contact details',
      onClick: index => {
        setCurrentStep(index);
      },
    },
    {
      title: 'Company details',
      srText: 'Company details',
      onClick: index => {
        setCurrentStep(index);
      },
    },
    {
      title: 'Billing details',
      srText: 'Billing details',
      onClick: index => {
        setCurrentStep(index);
      },
    },
  ];

  const horizontalStepperProps: HorizontalStepperProps = {
    currentStep,
    steps,
    inverse: false,
  };

  const [contactFormValid, setContactFormValid] = useState<boolean>(false);
  const [companyFormValid, setCompanyFormValid] = useState<boolean>(false);
  const [billingFormValid, setBillingFormValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [registerForm, setRegisterForm] = useState<RegisterForm>({
    company: null,
    billing: null,
    contact: null,
  });

  const [businessSize, setBusinessSize] = useState<number | null>(null);

  const { setErrorInfo, toggleModal } = useModalContext();

  const handleFormSubmit = async () => {
    if (!registerForm.contact || !registerForm.company || !registerForm.billing) {
      return;
    }
    const day = registerForm.contact['Date of birth'].value as string;
    const month = registerForm.contact['month'].value as string;
    const year = registerForm.contact['year'].value as string;
    const birthDate = new Date(year + '/' + month + '/' + day);
    const phoneNumber =
      (registerForm.contact['Phone number'].value as string) + registerForm.contact['contactphone'].value;
    const payload: UserRegistrationPayload = {
      user: {
        firstName: registerForm.contact['Name'].value as string,
        lastname: registerForm.contact['Last Name'].value as string,
        birthDate: birthDate.toISOString(),
        email: registerForm.contact['Email address'].value as string,
        phoneNumber: phoneNumber,
        eircode: registerForm.contact['Eircode'].value as string,
        address1: registerForm.contact['Address 1'].value as string,
        address2: registerForm.contact['Address 2'].value as string,
        city: registerForm.contact['City'].value as string,
        country: registerForm.contact['Country'].value as string,
      },
      company: {
        companyName: registerForm.company['Company name'].value as string,
        companySize: registerForm.company['Company size'].value as string,
        industryType: registerForm.company['Industry Type'].value as string,
        businessType: registerForm.company['Business type'].value as string,
        eircode: registerForm.company['Eircode'].value as string,
        address1: registerForm.company['Address 1'].value as string,
        address2: registerForm.company['Address 2'].value as string,
        city: registerForm.company['City'].value as string,
        country: registerForm.company['Country'].value as string,
        iban: registerForm.billing['IBAN'].value as string,
        registrationNumber: registerForm.company['Company Registration number'].value as string,
      },
    };
    setLoading(true);
    const res = await registerUser(payload, setErrorInfo, toggleModal);

    setLoading(false);

    if (res && res.status == 200) {
      setCurrentStep(4);
    }
  };

  const validCheck = useCallback((isValid: boolean, formType: 'contact' | 'company' | 'billing') => {
    if (formType == 'contact') {
      setContactFormValid(isValid);
    } else if (formType == 'company') {
      setCompanyFormValid(isValid);
    } else if (formType == 'billing') {
      setBillingFormValid(isValid);
    }
  }, []);

  const getButtonStatus = () => {
    if (currentStep == 0) {
      return businessSize == 3 || businessSize == null ? 'disabled' : (undefined as ButtonProps['state']);
    } else if (currentStep == 1) {
      return (contactFormValid ? undefined : 'disabled') as ButtonProps['state'];
    } else if (currentStep == 2) {
      return (companyFormValid ? undefined : 'disabled') as ButtonProps['state'];
    } else if (currentStep == 3) {
      return (billingFormValid ? undefined : 'disabled') as ButtonProps['state'];
    }
  };

  const getCurrentStepText = (subtitle: boolean = false) => {
    switch (currentStep) {
      case 1:
        return subtitle ? contactDetailsSubTitle : contactDetailsTitle;
      case 2:
        return subtitle ? companyDetailsSubTitle : companyDetailsTitle;
      case 3:
        return subtitle ? billingDetailsSubTitle : billingDetailsTitle;
      default:
        return '';
    }
  };

  if (currentStep == 4) {
    return <SuccessPage />;
  }

  return (
    <FormStateProvider>
      <RegisterAccountWrapper formTheme={theme}>
        {currentStep != 0 && (
          <TitleWrapper>
            <RegisterTitle formTheme={theme}>{getCurrentStepText()}</RegisterTitle>
            <Spacer h={50} />
            <StepperWrapper>
              <HorizontalStepper {...horizontalStepperProps} />
            </StepperWrapper>
            <Spacer h={50} />
            <RegisterSubtitle formTheme={theme}>{getCurrentStepText(true)}</RegisterSubtitle>
            <Spacer h={20} />
          </TitleWrapper>
        )}
        <ContentWrapper>
          {currentStep == 0 && (
            <BusinessSize
              title={businessSizeTitle}
              subTitle={businessSizeSubTitle}
              onChange={setBusinessSize}
              formTheme={theme}
            />
          )}
          {currentStep == 1 && (
            <ContactDetails
              formTheme={theme}
              validCallback={validCheck}
              onSubmit={form => {
                setRegisterForm({
                  ...registerForm,
                  contact: form,
                });
              }}
            />
          )}
          {currentStep == 2 && (
            <CompanyDetails
              formTheme={theme}
              validCallback={validCheck}
              onSubmit={form => {
                setRegisterForm({
                  ...registerForm,
                  company: form,
                });
              }}
            />
          )}
          {currentStep == 3 && (
            <BillingDetails
              formTheme={theme}
              validCallback={validCheck}
              setShowTermsAndConditions={setShowTermsAndConditions}
              onSubmit={form => {
                setRegisterForm({
                  ...registerForm,
                  billing: form,
                });
              }}
            />
          )}
          <BottomButtonsWrapper>
            <Button
              appearance="alt1"
              state={currentStep == 0 ? 'disabled' : undefined}
              loading={loading}
              text={'Back'}
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            />
            <Button
              appearance="primary"
              state={getButtonStatus()}
              loading={loading}
              text={currentStep == 3 ? 'Create my account' : 'Continue'}
              onClick={() => {
                if (currentStep == 3) {
                  handleFormSubmit();
                } else {
                  setCurrentStep(currentStep + 1);
                }
              }}
            />
          </BottomButtonsWrapper>
        </ContentWrapper>
      </RegisterAccountWrapper>
      {showTermsAndConditions && <TermsAndConditions onAccept={() => setShowTermsAndConditions(false)} />}
    </FormStateProvider>
  );
};

export default RegisterAccount;
